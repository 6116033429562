import React, { Component } from 'react';
import { connect } from 'react-redux';

import Select from '../Select';
import { Csku } from '@commonsku/styles';

class Filter extends Component {

  constructor(props) {
    super(props);

    this.state = {
      shift: 0,
      scrolling: false
    };

    this.titles = [];

    this.shiftLeft = this.shiftLeft.bind(this);
    this.shiftRight = this.shiftRight.bind(this);
    this.onResize = this.onResize.bind(this);
  }

  shiftLeft() {
    if (this.state.shift > 0) {
      this.setState({ shift: this.state.shift - 1 });
    }
  }

  shiftRight() {
    if (this.state.shift < this.props.titles.length - 1) {
      this.setState({ shift: this.state.shift + 1 });
    }
  }

  componentDidMount() {
    this.onResize();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.windowWidth !== nextProps.windowWidth) {
      this.onResize();
    }
  }

  onResize() {
    const filterWidth = this.container ? this.container.getBoundingClientRect().width : 0;
    const titlesWidth = this.titles.reduce((w, t) => w + t.getBoundingClientRect().width, 0);
    this.setState({
      scrolling: titlesWidth > filterWidth
    });
  }

  render() {
    const { filter, style, titles, onFilter, template_color = '#5ca3b6', items, xsStyle={}, mdStyle={} } = this.props;
    const { shift, scrolling } = this.state;
    if (!titles.length) {
      return null;
    }

    const activeFilterStyle = {
      background: template_color,
      color: 'white',
      borderRadius: '40px',
    };

    const titleOptions = [{ key: '', value: 'View All' }].concat(
      titles.map((t, i) => ({ key: t.item_id, value: t.item_name || `Category ${i+1}` }))
    );
    return (
      <div className="row" style={style}>
        <div className="small-12 columns show-for-small-only" style={{ marginTop: '0.5rem', ...xsStyle }}>
          <Select options={titleOptions} value={filter} change={onFilter} />
        </div>
        <Csku forceStyles
          className="small-12 columns row hide-for-small-only"
          style={{ overflow: 'hidden', whiteSpace: 'nowrap', height: '50px', lineHeight: '50px', ...mdStyle }}
          sx={{
            sm: { marginTop: 82 },
            md: { marginTop: 0 },
          }}
        >
          <div style={{ width: '10%', float: 'left', textAlign: 'center' }}>
            {scrolling && shift > 0 ?
              <a className="gallery-filter" onClick={this.shiftLeft}>&lsaquo;</a> :
              <span className="gallery-filter">&nbsp;</span>
            }
          </div>
          <div ref={ ref => { this.container = ref; }} style={{ width: '80%', float: 'left', overflow: 'hidden', textAlign: scrolling ? 'left' : 'center' }}>
            {titles.slice(shift, titles.length).map((t, i) =>
              <a
                key={t.item_id}
                ref={ref => { this.titles[i] = ref; }}
                className="gallery-filter"
                style={filter === t.item_id ? activeFilterStyle : { color: template_color }}
                onClick={e => onFilter(filter === t.item_id ? null : t.item_id)}
              >
                {t.item_name || `Category ${i+1}`} {filter === t.item_id ? <span>&nbsp;&times;</span> : null}
              </a>
            )}
          </div>
          <div style={{ width: '10%', float: 'left', textAlign: 'center' }}>
            {scrolling && shift < titles.length ?
              <a className="gallery-filter" onClick={this.shiftRight}>&rsaquo;</a> :
              <span className="gallery-filter">&nbsp;</span>
            }
          </div>
        </Csku>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  titles: ownProps.items
    .filter(i => 'SEPARATOR' === i.parent_type)
    .map((t, i) => ({ ...t, item_name: t.item_name || `Category ${i+1}` }))
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
