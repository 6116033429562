import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextArea from 'react-textarea-autosize';

import { Medium } from '../ScreenSize';

import { createAddComment } from '../../actions';

import { parseMysqlDate } from '../../utils';

class ItemComments extends Component {

  constructor(props) {
    super(props);

    this.state = {
      commenter_name: '',
      comment: ''
    };

    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeComment = this.handleChangeComment.bind(this);
    this.handleAddComment = this.handleAddComment.bind(this);
  }

  handleChangeName(e) {
    this.setState({ commenter_name: e.target.value });
  }

  handleChangeComment(e) {
    this.setState({ comment: e.target.value });
  }

  handleAddComment(e) {
    e.preventDefault();

    const { item } = this.props;
    const { commenter_name, comment } = this.state;

    this.props.onAddComment(item.item_id, commenter_name, comment);
    this.setState({ comment: '' });
  }

  render() {
    const { item, className = '', style = {} } = this.props;
    const { commenter_name, comment } = this.state;
    const comments = Object.values(item.comments);

    const repCommentStyle = {
      background: '#fdffe5'
    };

    return (
      <div className={`small-12 columns ${className}`} style={{ background: 'white' }}>
        <ul style={{ listStyle: 'none', marginLeft: '0.25rem', paddingRight: '0.25rem', ...style }}>
          {comments.map(c =>
            <li key={c.presentation_comment_id} style={1 == c.rep_comment ? repCommentStyle : null}>
              <strong>{c.commenter_name}:</strong>
              <span style={{ float: 'right', fontSize: '0.8em' }}>{parseMysqlDate(c.date_created)}</span>
              <p style={{ wordBreak: 'break-word' }}>{c.comment}</p>
            </li>)}
        </ul>
        <input type="text" value={commenter_name} placeholder="Your name" onChange={this.handleChangeName} />
        <TextArea value={comment} placeholder="Comment on this product..." onChange={this.handleChangeComment}></TextArea>
        <button className="button" disabled={!commenter_name || !comment} onClick={this.handleAddComment}>Add Comment</button>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onAddComment: (item_id, commenter_name, comment) => dispatch(createAddComment(item_id, null, commenter_name, null, comment))
});

export default connect(null, mapDispatchToProps)(ItemComments);
