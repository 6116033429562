import React, { Component } from 'react';

import { getImageSrc } from '../../utils';

import Img from '../Img';

class FilmStrip extends Component {

  constructor(props) {
    super(props);

    this.state = {
      index: this.props.index || 0
    };

    this.onSelectFrame = this.onSelectFrame.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.index !== undefined && nextProps.index !== this.state.index) {
      this.setState({ index: nextProps.index });
    }
  }

  onSelectFrame(index) {
    this.setState({ index });
    if (this.props.onSelect) {
      const { items } = this.props;
      const item = items[index];
      this.props.onSelect(item.item_id);
    }
  }

  renderFrame(item, index) {
    const { size = 100 } = this.props;

    const frameStyle = {
      display: 'inline-block',
      position: 'relative',
      width: `${size}px`,
      height: `${size}px`,
      marginLeft: '4px',
      marginRight: '4px',
      textAlign: 'center',
      background: 'white',
      verticalAlign: 'middle'
    };

    const imageStyle = {
      maxWidth: '100%',
      maxHeight: '100%',
      position: 'relative',
      top: '50%',
      transform: 'translateY(-50%)'
    };

    return (
      <div key={item.item_id} style={frameStyle} onClick={e => this.onSelectFrame(index)}>
        <Img style={imageStyle} src={getImageSrc(item.file)} />
      </div>
    );
  }

  render() {
    const { items, size = 100, template_color = 'rgb(51, 122, 183)', highlightActive = true, className = '', style = {} } = this.props;

    const activeStyle = {
      position: 'absolute',
      width: `${size}px`,
      height: `${size}px`,
      left: '50%',
      transform: 'translateX(-50%)',
      border: `4px solid ${template_color}`,
      background: 'transparent',
      zIndex: 1
    };

    const filmStripStyle = {
      transform: 'translate3d(calc(50% - ' + (size / 2 + 4) + 'px - ' + (this.state.index * (size + 8)) + 'px), 0px, 0px)',
      transition: 'transform .5s ease-out'
    };

    return (
      <div className={`row collapse ${className}`} style={{ margin: '1rem auto', ...style }}>
        <div className="small-12 columns" style={{ position: 'relative', overflow: 'hidden', height: `${size}px`, whiteSpace: 'nowrap', cursor: 'pointer' }}>
          {highlightActive ? <div style={activeStyle}>&nbsp;</div> : null}
          <div style={filmStripStyle}>
            {items.map((item, index) => this.renderFrame(item, index))}
          </div>
        </div>
      </div>
    );
  }
}

export default FilmStrip;
