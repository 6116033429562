import { sizeSort } from '../utils';

export function sortItemWithInventory(item) {
    // We try to reorder based on color and size
    let newItems = [];
    let colorGroupedItems = {};
    for (const ii of item.inventory_items) {
        let iiOptions = null;
        if (ii.product_sku_id) {
            iiOptions = item.item_skus.find(s => s.product_sku_id === ii.product_sku_id)?.options;
        }
        const newItem = {
            ...ii,
            color_name: iiOptions ? iiOptions.find(o => o.option_axis === 'color')?.option_name ?? '' :
                item.item_colors.find(c => c.color_id === ii.color_id)?.color_name ?? '',
            size_name: iiOptions ? iiOptions.find(o => o.option_axis === 'size')?.option_name ?? '' :
                item.item_sizes.find(s => s.size_id === ii.size_id)?.size_name ?? '',
        };
        if (!colorGroupedItems[newItem.color_name]) {
            colorGroupedItems[newItem.color_name] = [newItem];
        } else {
            colorGroupedItems[newItem.color_name].push(newItem);
        }
    }

    for (const colorKey of Object.keys(colorGroupedItems).sort()) {
        const orderedItems = colorGroupedItems[colorKey].sort((a, b) => sizeSort(a.size_name, b.size_name));
        for (const orderedItem of orderedItems) {
            newItems.push({ ...orderedItem, description: getColorSizeDescription(orderedItem.color_name, orderedItem.size_name) });
        }
    }

    return newItems;
}

export function getColorSizeDescription(color, size) {
    if (!!size) {
        if (!!color) {
            return `${color} / ${size}`;
        }
        return size;
    } else if (!!color) {
        return color;
    }
    return '';
}